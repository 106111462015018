<template>
  <div>
    <TagbyFilter
      :categoryOptions="categoryOptions"
      :initial-search-params="filterParams"
      @search="search"
    />

    <TagbyList
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >

      <template #cell(idx)="data">
        <BLink :to="{ name: 'screenshot-job-detail', params: { idx: data.value } }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(campaign_idx)="data">
        <div v-if="data.item.campaign_idx != null">
          <div
            style="width: 150px;"
            class="ext-truncate"
          >
            {{ data.item.campaign_title }}
          </div>
          <BLink
            v-if="data.item.campaign_state === 'HIDDEN'"
            :to="{ name: 'campaign-compt-hidden-detail', params: { idx: data.item.campaign_idx } }"
          >
            ({{ data.item.campaign_idx }})
          </BLink>
          <BLink
            v-else
            :to="{ name: 'campaign-compt-active-detail', params: { idx: data.item.campaign_idx } }"
          >
            ({{ data.item.campaign_idx }})
          </BLink>
        </div>
      </template>

      <template #cell(adv_idx)="data">
        <div v-if="data.item.adv_idx != null">
          {{ data.item.adv_name }}
          <BLink
            v-if="data.item.adv_idx != null"
            :to="{ name: 'member-adv-view', params: { idx: data.item.adv_idx } }"
          >
            ({{ data.item.adv_idx }})
          </BLink>
        </div>
      </template>

      <template #cell(start_at)="data">
        <div style="width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>

      <template #cell(end_at)="data">
        <div style="width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <div style="width: 90px;">
          {{ resolveDateTime(data.value) }}
        </div>
      </template>
    </TagbyList>
  </div>
</template>

<script>
import {
  BLink,
} from "bootstrap-vue"
import resolveValue from "@/utils/resolveValue"
import {
  useInitialize,
  useFilter,
  useTable,
} from "./viewModel"
import TagbyFilter from "@/components/TagbyFilter.vue"
import TagbyList from "@/components/TagbyList.vue"

export default {
  components: {
    BLink,
    TagbyFilter,
    TagbyList,
  },
  setup() {
    const { resolveDateTime } = resolveValue()
    const { initialize } = useInitialize()
    initialize()
    const filter = useFilter()
    const { fetchItems } = filter
    fetchItems()
    const table = useTable()
    const resolveCampaignTitle = item => item.campaign?.title
    return {
      resolveDateTime,
      resolveCampaignTitle,
      ...filter,
      ...table,
    }
  },
}
</script>
