import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'

import store from '@/store'
import router from '@/router'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'
import useToast from '@/utils/toast'
import useStore from '@/utils/store'
import screenshotJobStoreModule from '@/store/tagby/screenshotJob'
// import storeModule from './storeModule'

const currentPage = ref()
const perPage = ref()
const totalRows = ref()

const items = ref([])
const isBusy = ref(false)

const filterParams = ref()
const searchParams = computed(() => ({
  page: currentPage.value,
  size: perPage.value,
  total: totalRows.value,
  ...filterParams.value,
}))

export function useInitialize() {
  const initialize = () => {
    const routerQuery = router.history.current.query
    currentPage.value = parseInt(routerQuery.page, 10) || 1
    totalRows.value = parseInt(routerQuery.total, 10)
    perPage.value = parseInt(routerQuery.size, 10) || 20

    const params = syncronizeParamsWithUrl()
    filterParams.value = params

    onUnmounted(() => {
      if (store.hasModule(screenshotJobStoreModule.STORE_MODULE_NAME)) {
        store.unregisterModule(screenshotJobStoreModule.STORE_MODULE_NAME)
      }
    })
  }

  return {
    initialize,
  }
}

export function useFilter() {
  const categoryOptions = [
    {
      label: 'STATE',
      paramName: 'state',
      format: 'checkbox',
      options: [
        { text: 'ACTIVE', value: 'ACTIVE' },
        { text: 'INACTIVE', value: 'INACTIVE' },
      ],
    },
    {
      label: 'CAMPAIGN_IDX',
      paramName: 'campaign_idx',
    },
  ]

  const { dispatch } = useStore(screenshotJobStoreModule.STORE_MODULE_NAME)
  const { makeToask } = useToast()

  if (!store.hasModule(screenshotJobStoreModule.STORE_MODULE_NAME)) {
    store.registerModule(screenshotJobStoreModule.STORE_MODULE_NAME, screenshotJobStoreModule)
  }

  const fetchItems = () => {
    isBusy.value = true
    dispatch('fetchItems', {
      ...searchParams.value,
    }).then(response => {
      const { page_info, data } = response.data
      items.value = data
      totalRows.value = page_info.total_count
      replaceRouterQuery(searchParams.value)
    }).catch(() => {
      makeToask('danger', '목록을 쿼리하는데 실패 했습니다')
    }).finally(() => {
      isBusy.value = false
    })
  }

  const search = params => {
    currentPage.value = 1
    filterParams.value = {
      ...filterParams.value,
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    fetchItems()
  }

  return {
    categoryOptions,
    filterParams,
    fetchItems,
    search,
  }
}

export function useTable() {
  const tableColumns = [
    { key: 'idx' },
    { key: 'campaign_idx', label: 'campaign' },
    { key: 'adv_idx', label: 'adv' },
    // { key: 'posting_count', label: 'posting_count' },
    { key: 'keywords', label: 'keywords' },
    // { key: 'interval', label: 'interval' },
    // { key: 'start_at', label: 'start_at' },
    // { key: 'end_at', label: 'end_at' },
    { key: 'state', label: 'state' },
    { key: 'created_at', label: 'created_at' },
  ]

  const { fetchItems } = useFilter()
  const changePage = params => {
    currentPage.value = params.page
    fetchItems()
  }

  return {
    tableColumns,
    changePage,
    items,
    currentPage,
    perPage,
    totalRows,
    isBusy,
  }
}

export function others() {

}
