import axios from '@axios'

const STORE_MODULE_NAME = 'screenshot-job'

const actions = {
  fetchItems(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/screenshot-job/`, { params })
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchItem(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/screenshot-job/${idx}/`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateItem(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/screenshot-job/update/`, params)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  fetchTimelines(ctx, { idx }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_BACKEND_SERVER}/manage/screenshot-job/${idx}/timelines/`)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
}

export default {
  STORE_MODULE_NAME,
  namespaced: true,
  actions,
}
